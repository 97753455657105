import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { Link, graphql } from "gatsby";
import { ChapterNav, TextSection } from "./../styledcomponents/ChapterStyles";
import Img from "gatsby-image";

import Layout from "../components/layout";

const ChapterPage = ({ data, pageContext }) => {
  const { frontmatter, body } = data.mdx;
  const { file } = data;
  const { previous, next } = pageContext;
  const ChapterNavSection = () => (
    <ChapterNav>
      {previous ? (
        <Link to={`/chapter/${previous}`}>Previous chapter</Link>
      ) : (
        <span>&nbsp;</span>
      )}
      {next && <Link to={`/chapter/${next}`}>Next chapter</Link>}
    </ChapterNav>
  );
  return (
    <Layout>
      <ChapterNavSection />
      <TextSection>
        <h1>Chapter {frontmatter.id}:</h1>
        <h2>{frontmatter.title}</h2>
        <MDXProvider>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
        {file ? <Img fluid={file.childImageSharp.fluid} /> : null}
      </TextSection>
      <ChapterNavSection />
    </Layout>
  );
};

export default ChapterPage;

export const pageQuery = graphql`
  query TextQuery($id: String, $imageFile: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        id
        title
        secret
      }
    }
    file(relativePath: { eq: $imageFile }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
